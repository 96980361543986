<template>
    <div class="account-content page-template-account">


        <content-loader v-if="loaded==false" viewBox="0 0 520 230" primaryColor="#f3f3f3" secondaryColor="#cccccc">

            <rect x="110" y="20" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="60" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="100" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="140" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="180" rx="5" ry="5" width="300" height="10" />

        </content-loader>
        
        <template v-else>
            <PopupLogin
                :language="language"
                :displayLoginForm="displayLoginForm" :displayRegisterForm="displayRegisterForm"
                :errorLogin="errorLogin" :errorRegister="errorRegister" @loginDataEv="loginDataChange"
                @registerDataEv="registerDataChange" @displayRegisterEv="displayRegister" @displayLoginEv="displayLogin"
                @displayForgotPasswordEv="displayForgotPassword" @login_accountEv="login_account"
                @register_accountEv="register_account"
                :register_name="register_name"
                :register_surname="register_surname"
                :register_email="register_email"
                :register_password="register_password"
                :register_phone="register_phone"
                :register_password_repeat="register_password_repeat"
                
            />

            <div id="error">{{ error }}</div>

                <OverlayComponent :language="language" v-if="visible"
                :title="title" :txt="txt" @remove="visible=false" />

            <AccountContent
                :language="language"
                :displayReviewContent="displayReviewContent" :displayAccountContent="displayAccountContent"
                :displayPopupShareScan="displayPopupShareScan" :errorShare="errorShare" :account_name="account_name"
                :account_surname="account_surname" :account_email="account_email"
                :displayAddressContent="displayAddressContent" :addresses="addresses"
                :displayPrescriptionContent="displayPrescriptionContent" :userPrescriptions="userPrescriptions"
                :prescriptionSelected="prescriptionSelected" :userReviews="userReviews"
                :displayScanContent="displayScanContent" :userScans="userScans" :loadingScans="loadingScans" :displayEditScanForm="displayEditScanForm"
                :displayCards="displayCards" :cards="cards" :displayEditAccount="displayEditAccount"
                :displayFormCard="displayFormCard" :displayEditAddressForm="displayEditAddressForm"
                :displayAddAddressForm="displayAddAddressForm" :name_edit_address="name_edit_address"
                :address1_edit_address="address1_edit_address" :address2_edit_address="address2_edit_address"
                :postcode_edit_address="postcode_edit_address" :city_edit_address="city_edit_address"
                :country_edit_address="country_edit_address" :id_edit_scan="id_edit_scan" :name_edit_scan="name_edit_scan"
                :displayOrderList="displayOrderList" :orders="orders" :displayAccountDetails="displayAccountDetailsContent"

                @deleteScanEv="deleteScan" @shareScanEv="shareScan" @displayPopupScanEv="displayPopupScan"
                @displayPopupShareScanToFalseEv="displayPopupShareScan = false" @selectEv="selectItem"
                @editAccountEv="editAccount" @editAddressEv="editAddress" @deleteAddressEv="deleteAddress"
                @addAddressEv="addAddress" @deletePrescriptionEv="deletePrescription" @logoutEv="logout"
                @displayPrescriptionDetailsEv="displayPrescriptionDetails" @editScanEv="editScan"
                @deleteReview="deleteReview" @updateScanEv="updateScan" @changeIDScanEv="changeIDScan"
                @changeNameScanEv="changeNameScan" @removeCardEv="removeCard" @addCardFormEv="addCardForm"
                @addCardEv="addCard" @itemAddressChangeEv="itemAddressChange" @createAddressEv="createAddress"
                @setDefaultScanEv="setDefaultScan"
                @itemAddressCreateChangeEv="itemAddressCreateChange" @updateAddressEv="updateAddress" />


          
           
        </template>
    </div>
    <PopupScan v-if="displayScanPopup" :language="language" :scan="currentScanPopup" />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
</template>

<script>

import { Auth, APIRequest } from "@simb2s/senseye-sdk";
import google from 'google';
import emitter from 'tiny-emitter/instance'
import Stripe from 'stripe';
import Footer from '../components/layout/Footer.vue'
import PopupScan from '../components/elements/PopupScan.vue'
import { ContentLoader } from 'vue-content-loader';
import jQuery from 'jquery';
import parseJwt from '../utils/jwt/extractPayload';

import OverlayComponent from "../components/overlay/OverlayComponent.vue";

// import children
import PopupLogin from "../components/popup/PopupLogin.vue"
import AccountContent from "../components/children/Account/AccountContentComponent.vue"
import { useAuthStore } from '@/store/authStore.js';
import { useCartStore } from '@/store/cartStore.js';
import { useMeta } from 'vue-meta'

export default {
    name: 'Account',
    components: {
        Footer,
        PopupScan,
        ContentLoader,
        PopupLogin,
        AccountContent,
        OverlayComponent
    },
    data() {
        return {
            useAuth: useAuthStore(),
            useCart: useCartStore(),
            visible: false,
            authLoaded: false,
            title: "",
            stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
            elements: '',
            shareEmail: "",
            displayPopupShareScan: false,
            currentScanShare: null,
            currentScanPopup: null,
            displayScanPopup: false,
            prescriptionSelected: null,
            card: '',
            cart: null,
            pickup_list: [],
            userScans: [],
            userReviews: [],
            loadingScans:false,
            displayPickups: false,
            user: null,
            username: '',
            loaded: false,
            cards: [],
            orders: [],
            addresses: [],
            stripe: null,
            displayFormCard: false,
            displayOrderList: false,
            displayCards: false,
            register_name: '',
            register_surname: '',
            register_password: '',
            register_phone: '',
            register_email: '',
            register_password_repeat:'',
            email: '',
            errorShare: '',
            password: '',
            errorRegister: '',
            errorLogin: '',
            error: '',
            displayRegisterForm: false,
            displayLoginForm: false,
            order_nb: 0,
            account_username: null,
            account_surname: null,
            account_email: null,
            account_phone: null,
            account_name: null,
            account_id: null,
            account_customer_stripe_id: null,
            cardElement: null,
            displayAccountContent: false,
            displayAddressForm: false,
            displayAddressContent: false,
            displayScanContent: false,
            displayAccountDetailsContent:false,
            displayReviewContent: false,
            userPrescriptions: [],
            displayEditAccount: false,
            displayPrescriptionContent: false,
            displayEditAddressForm: false,
            displayAddAddressForm: false,
            displayEditScanForm: false,
            displayEditPrescriptionForm: false,
            address_address1: '',
            address_address2: '',
            address_name: '',
            address_first_name: '',
            address_last_name: '',
            address_phone: '',
            address_postcode: '',
            address_default_address: false,
            address_city: '',
            address_country: '',
            id_edit_address: '',
            id_edit_scan: '',
            index_edit_scan: '',
            id_edit_prescription: '',
            index_edit_prescription: '',
            name_edit_scan: '',
            name_edit_address: '',
            address1_edit_address: '',
            address2_edit_address: '',
            postcode_edit_address: '',
            city_edit_address: '',
            country_edit_address: '',
            default_address_edit_address: false
        }
    },
    watch: {
        'useAuth.authLoaded'(newVal, oldVal){
           
            if(newVal==true) {
                this.loaded=true
            }
            else {
                this.loaded=false
            }
        },
        loaded(){
           this.$forceUpdate()
        },
     
        displayAccountContent(newVal, oldVal){
         
        }
    },
    props:["displayModalMenu","language"],
    methods: {
        async userLogout () {
            this.loaded = false
            await this.loadUserContent()
            this.loaded=true
        },
        loginDataChange(input, value) {
            switch (input) {
                case "username":
                    this.username = value;
                    break;
                case "password":
                    this.password = value;
                    break;
            }
        },
        registerDataChange(input, value) {
            switch (input) {
                case "register_name":
                    this.register_name = value;
                    break;
                case "register_surname":
                    this.register_surname = value;
                    break;
                case "register_email":
                    this.register_email = value;
                    break;
                case "register_password":
                    this.register_password = value;
                    break;
                case "register_phone":
                    this.register_phone = value;
                    break;
                case "register_password_repeat":
                    this.register_password_repeat = value;
                    break;
            }
        },
        async selectItem(link) {
            switch (link) {
                case "accountDetails":
                    this.displayAccountDetails()
                    break;
                case "orders":
                    this.displayOrders()
                    break;
                case "cardsDetails":
                    this.displayCardsDetails()
                    break;
                case "addresses":
                    this.displayAddresses()
                    break;
                case "scans":
                    this.displayScans()
                    break;
                case "favorites":
                    this.$router.push({ name: 'likes' })
                    break;
                case "reviews":
                    this.displayReviews()
                    break;
                case "prescriptions":
                    this.displayPrescriptions()
                    break;
            }
        },
        itemAddressChange(link, value) {
            switch (link) {
                case "id":
                    this.id_edit_address = value;
                    break;
                case "name":
                    this.name_edit_address = value;
                    break;
                case "address1":
                    this.address1_edit_address = value;
                    break;
                case "address2":
                    this.address2_edit_address = value;
                    break;
                case "postcode":
                    this.postcode_edit_address = value;
                    break;
                case "city":
                    this.city_edit_address = value;
                    break;
                case "country":
                    this.country_edit_address = value;
                    break;
            }
        },
        itemAddressCreateChange(link, value) {
            switch (link) {
                case "address_name":
                    this.address_name = value
                    break;
                case "address_address1":
                    this.address_address1 = value
                    break;
                case "address_address2":
                    this.address_address2 = value
                    break;
                case "address_postcode":
                    this.address_postcode = value
                    break;
                case "address_city":
                    this.address_city = value
                    break;
                case "address_first_name":
                    this.address_first_name = value
                    break;
                case "address_last_name":
                    this.address_last_name = value
                    break;
                case "address_phone":
                    this.address_phone = value
                    break;
                case "address_country":
                    this.address_country = value
                    break;
            }
        },
        changeIDScan(e) {
            this.id_edit_scan = e;
        },
        changeNameScan(e) {
            this.name_edit_scan = e;
        },
        displayPopupScan(id) {
         
            this.currentScanPopup = id
            this.displayScanPopup = true
        },
        displayForgotPassword() {
            emitter.emit('displayforgotpassword');
        },
        async reloadContents() {
            this.loaded = false;

            await this.getOrders()
            await this.getAddresses()
            await this.getCards()
            await this.getUserScans()
            await this.getUserReviews()
            await this.getUserPrescriptions()
            this.loaded = true;
            this.displayAccountContent = true
        },
        
        async getOrders() {
           
            let orders = await APIRequest.list('orders', localStorage.id_user_senseye_auth,null,10000)
            this.orders = orders[0];
            this.order_nb = 0;
            for (let i = 0; i < this.orders.length; i++) {
                if (this.orders[i].payment != null) {
                    this.order_nb++;
                }
            }
            
        },
        async getAddresses() {
            let addresses = await APIRequest.list('addresses', localStorage.id_user_senseye_auth,null,10000)
            this.addresses = addresses[0];
            
        },
        async updateAddress() {
            this.loaded = false
            if (this.address1_edit_address != "" &&
                this.name_edit_address != "" &&
                this.postcode_edit_address != "" &&
                this.city_edit_address != "" &&
                this.country_edit_address != ""

            ) {
                let Parameters = {};
                Parameters.address1 = this.address1_edit_address
                Parameters.address2 = this.address2_edit_address
                Parameters.name = this.name_edit_address
                Parameters.postcode = this.postcode_edit_address
                Parameters.city = this.city_edit_address
                Parameters.country = this.country_edit_address
                Parameters.default_address = this.default_address_edit_address
                let id_address_edit = this.id_edit_address
                
                await APIRequest.update('addresses', id_address_edit, Parameters)
                await this.getAddresses()
                this.displayEditAddressForm = false
                this.loaded = true
                this.displayAddressContent = true
                // jQuery('html, body').animate({
                //     scrollTop: jQuery("#content-addresses").offset().top
                // }, 500);
            }
            else {
                this.showModal("Tous les champs doivent être remplis !")
                
            }
        },
        showModal(title) {
                this.loaded = true
                this.visible = true;
                this.title = title;
            },
        async deleteScan(id) {
            let id_scan = id;
            this.displayScanContent = true
            await APIRequest.delete('scans', id_scan)
            this.loaded = true
            await this.getUserScans()
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-scans").offset().top
            // }, 500);
        },
        async deleteReview(id) {
            let id_review = id;
            this.displayReviewContent = true
            await APIRequest.delete('reviews', id_review)
            this.loaded = true
            await this.getUserReviews()
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-reviews").offset().top
            // }, 500);
        },
        async deletePrescription(id) {
            let id_prescription = id;
            this.displayPrescriptionContent = true
            await APIRequest.delete('prescriptions', id_prescription)
            this.loaded = true
            await this.getUserPrescriptions()
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-prescriptions").offset().top
            // }, 500);
        },
        async updateScan() {
            this.loaded = false
            if (this.name_edit_scan != "") {
                let Parameters = this.userScans[this.index_edit_scan];
                Parameters.name = this.name_edit_scan
                let id_scan_edit = this.id_edit_scan
                await APIRequest.update('scans', id_scan_edit, Parameters)
                await this.getUserScans()
                this.displayEditScanForm = false
                this.loaded = true
                this.displayScanContent = true
                // jQuery('html, body').animate({
                //     scrollTop: jQuery("#content-scans").offset().top
                // }, 500);
            }
            else {
                this.loaded = true
                this.showModal("Tous les champs doivent être remplis !");
                // alert('Tous les champs doivent être remplis !');
            }
        },
        async setDefaultScan(index) {
            let scan = this.userScans[index]
            let form = scan
            delete scan.user
            form.default_scan = true

            await APIRequest.update('scans', scan.id, form)

            this.loaded = true
            this.displayScanContent = true
            await this.getUserScans()
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-scans").offset().top
            // }, 500);
        },
        async deleteAddress(id) {
            let id_address = id;
            await APIRequest.delete('addresses', id_address)
            this.displayEditAddressForm = false
            this.loaded = true
            this.displayAddressContent = true
            await this.getAddresses()
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-addresses").offset().top
            // }, 500);
        },
        async createAddress() {
            this.loaded = false
            if (this.address_address1 != "") {
                let Parameters = {};
                Parameters.user = {};
                Parameters.user.id = localStorage.id_user_senseye_auth;
                Parameters.first_name = this.address_first_name;
                Parameters.last_name = this.address_last_name;
                Parameters.phone = this.address_phone;
                Parameters.address1 = this.address_address1;
                Parameters.address2 = this.address_address2;
                Parameters.name = this.address_name;
                Parameters.postcode = this.address_postcode;
                Parameters.city = this.address_city;
                Parameters.country = this.address_country;
                Parameters.default_address = this.address_default_address;
                await APIRequest.create('addresses', Parameters)
                await this.getAddresses()
                this.displayAddressForm = false
                this.displayAddressContent = true
                // jQuery('html, body').animate({
                //     scrollTop: jQuery("#content-addresses").offset().top
                // }, 500);
                this.loaded = true
            }
            else {
                this.showModal("Tous les champs doivent être remplis !");
                // alert('Tous les champs doivent être remplis !');
            }

        },
        async displayOrders() {
            this.displayOrderList = !this.displayOrderList
            if(this.displayOrderList==true)
            {

                await this.getOrders()

            }
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.order-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#order_list").offset().top
            // }, 500);
        },
        async displayCardsDetails() {
            this.displayCards = !this.displayCards
            if(this.displayCards==true)
            {
                await this.getCards()
            }
            
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.cards-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-cards").offset().top
            // }, 500);
        },
        displayAccountDetails() {
            this.displayAccountContent = true
            this.displayAccountDetailsContent = !this.displayAccountDetailsContent
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.account-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#account-details").offset().top
            // }, 500);
        },
        addAddress() {
            this.displayAddAddressForm = true;
            setTimeout(() => {
                const autocomplete = new google.maps.places.Autocomplete(document.getElementById('address1_create_address'));
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    document.getElementById('address1_create_address').value = place.address_components[0].short_name + ' ' + place.address_components[1].long_name;
                    this.address_address1 = place.address_components[0].short_name + ' ' + place.address_components[1].long_name;
                    document.getElementById('city_create_address').value = place.address_components[2].long_name;
                    this.address_city = place.address_components[2].long_name;
                    document.getElementById('country_create_address').value = place.address_components[5].long_name;
                    this.address_country = place.address_components[5].long_name;
                    document.getElementById('postcode_create_address').value = place.address_components[6].long_name;
                    this.address_postcode = place.address_components[6].long_name;
                });
            }, 10)
        },
        editPrescription(id, index) {
            var index_prescription = index;
            var id_prescription = id;
            this.index_edit_prescription = index
            this.id_edit_prescription = id_prescription;


            this.displayEditPrescriptionForm = true

            // jQuery('html, body').animate({
            //     scrollTop: jQuery('#content-prescriptions').offset().top + 500
            // }, 500);
        },
        editScan(id, index) {
            var index_scan = index;
            var id_scan = id;
            this.index_edit_scan = index
            this.id_edit_scan = id_scan;
            this.name_edit_scan = this.userScans[index_scan].name;

            this.displayEditScanForm = true

            // jQuery('html, body').animate({
            //     scrollTop: jQuery('#content-scans').offset().top + 500
            // }, 500);
        },
        editAddress(id, index) {
            var index_address = index;
            var id_address = id;
            this.id_edit_address = id_address;
            this.name_edit_address = this.addresses[index_address].name;
            this.address1_edit_address = this.addresses[index_address].address1;
            this.address2_edit_address = this.addresses[index_address].address2;
            this.postcode_edit_address = this.addresses[index_address].postcode;
            this.city_edit_address = this.addresses[index_address].city;
            this.country_edit_address = this.addresses[index_address].country;
            this.displayEditAddressForm = true
            const autocomplete2 = new google.maps.places.Autocomplete(document.getElementById('address1_edit_address'));
            autocomplete2.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                document.getElementById('address1_edit_address').value = place.address_components[0].short_name + ' ' + place.address_components[1].long_name;
                document.getElementById('city_edit_address').value = place.address_components[2].long_name;
                document.getElementById('country_edit_address').value = place.address_components[5].long_name;
                document.getElementById('postcode_edit_address').value = place.address_components[6].long_name;
            });
            // jQuery('html, body').animate({
            //     scrollTop: jQuery('#content-addresses').offset().top + 500
            // }, 500);
        },
        async displayAddresses() {
            this.displayAddressContent = !this.displayAddressContent
            if(this.displayAddressContent==true)
            {
                await this.getAddresses()
            }
            
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.addresses-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-addresses").offset().top
            // }, 500);
        },
        async displayScans() {
            this.displayScanContent = !this.displayScanContent

            if(this.displayScanContent==true)
            {
                await this.getUserScans() 
            }
            
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.scans-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-scans").offset().top
            // }, 500);
        },
        async displayPrescriptions() {
            this.displayPrescriptionContent = !this.displayPrescriptionContent

            if(this.displayPrescriptionContent==true)
            {
                await this.getUserPrescriptions()
            }
           
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.prescription-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-prescriptions").offset().top
            // }, 500);
        },
        async displayReviews() {
            this.displayReviewContent = !this.displayReviewContent

            if(this.displayReviewContent==true)
            {
                await this.getUserReviews()
            }
            
            // jQuery('.menu-account li').removeClass('active');
            // jQuery('.reviews-details-link').addClass('active');
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#content-reviews").offset().top
            // }, 500);
        },
        logout() {
            this.loaded = false
            this.$forceUpdate()

            this.logoutStore()

            
            //await Auth.logout()
            //emitter.emit('logoutSocket');
            //document.location.reload();
        },
        async logoutStore() {

            this.loaded = false
            this.$forceUpdate()
            await this.useAuth.handleLogout()
        },
        async getCards() {
            if (this.cart) {
                if (this.cart.user) {
                    if (this.cart.user != null) {
                        if (this.cart.user.customer_stripe_id != null) {
                            this.cards = Auth.list_cards(this.cart.user.customer_stripe_id)
                        }
                    }
                }
            }
        },
        async getUserScans() {

            this.loadingScans=true

            let userScans = await APIRequest.list('scans', localStorage.id_user_senseye_auth)
            this.userScans = userScans[0];
            this.loadingScans=false

        },


        checkEmail(text) {
            
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (reg.test(text) === false) {
                

                return false;
            }
            else {
                return true;
            }
        },
        async sendScan() {
            this.loaded = false
            if (this.checkEmail(this.shareEmail) == true) {
                let scan = await APIRequest.detail('scans', this.currentScanShare)
                let Parameters = {}
                Parameters.coordinates = scan.coordinates
                Parameters.measurements = scan.measurements
                Parameters.measure_coordinates = scan.measure_coordinates
                Parameters.date = scan.date
                Parameters.photo = scan.photo
                Parameters.device_model = scan.device_model
                Parameters.default_scan = false
                Parameters.name = scan.name

                let new_scan = await APIRequest.create('scans', Parameters)
                Parameters = {}
                Parameters.scan_id = new_scan.id
                Parameters.user_email = this.shareEmail

                await APIRequest.shareScan(Parameters)

                this.errorShare = 'Le scan a bien été envoyé !'
                this.loaded = true
            }
            else {
                this.errorShare = "L'adresse e-mail n'est pas valide."
                this.loaded = true
            }


        },
        shareScan(id) {
            this.displayPopupShareScan = true
            this.currentScanShare = id

        },
        async getUserReviews() {

            let userReviews = await APIRequest.list('reviews', localStorage.id_user_senseye_auth)
            this.userReviews = userReviews[0];
            // console.log(this.userReviews);

        },
        displayPrescriptionDetails(index) {
            this.prescriptionSelected = this.userPrescriptions[index]
            // jQuery('html, body').animate({
            //     scrollTop: jQuery("#prescription_content").offset().top
            // }, 500);
        },
        async getUserPrescriptions() {

            let userPrescriptions = await APIRequest.list('prescriptions', localStorage.id_user_senseye_auth)
            this.userPrescriptions = userPrescriptions[0];

        },
        async addCard() {
            this.stripe.createToken(this.card).then(function (result) {
                if (result.error) {
                    errors.textContent = result.error.message;
                    form.classList.add('has-error');
                    this.loaded = true
                    intiated = false;
                } else {
                    result.token.id;
                    Auth.add_card(this.cart.user.customer_stripe_id, result.token.id, localStorage.id_user_senseye_auth)
                    this.getCards()
                }
            });
        },
       
        async loginUser() {
            this.loaded = false
            console.log('log in')
            console.log(this.username)
            console.log(this.password)
            
            this.$emit('loginUserEv',  this.username, this.password)
            //await emitter.emit('login', this.username, this.password)

        },
        async register_account() {
            this.loaded = false
 

            const userObj = {
                email : this.register_email,
                password: this.register_password,
                name: this.register_name, 
                phone: this.register_phone,
                surname: this.register_surname, 
                username: this.register_username
            }
            await this.useAuth.registerUser(userObj)
        },
        

        async login_account() {
            this.loaded = false
            this.displayLoginForm = false
            await this.loginUser()
      
        },
        formatDate(date) {
            var monthNames = [
                "January", "February", "March",
                "April", "May", "June", "July",
                "August", "September", "October",
                "November", "December"
            ];
            var day = date.getDate();
            var monthIndex = date.getMonth();
            var year = date.getFullYear();
            return day + ' ' + monthNames[monthIndex] + ' ' + year;
        },
        async removeCard(stripe_id, card_id) {
            await Auth.remove_card(stripe_id, card_id);
            this.showModal('Votre carte a bien été supprimée !')
          
            await this.reloadContents()
        },
        async addCardForm() {
            this.displayFormCard = true
        },

        displayLogin() {
            this.displayLoginForm = true
            this.displayRegisterForm = false
        },

        displayRegister() {
            this.displayLoginForm = false
            this.displayRegisterForm = true
        },
        editAccount() {
            this.displayEditAccount = true;
            // setTimeout(() => {
            //     jQuery('.menu-account li').removeClass('active');
            //     jQuery('edit-account-details-link').addClass('active');
            //     jQuery('html, body').animate({
            //         scrollTop: jQuery("#account-details-form").offset().top
            //     }, 500);
            // }, 10);
        },
        async updateAccount() {
            this.loaded = false
            if (this.account_surname != "" && this.account_name != "" && this.account_email != "" && this.account_phone != "") {
                let Parameters = {};
                Parameters.username = this.account_email;
                Parameters.surname = this.account_surname;
                Parameters.name = this.account_name;
                Parameters.email = this.account_email;
                Parameters.phone = this.account_phone;
                await Auth.update_account(localStorage.id_user_senseye_auth, Parameters)
                jQuery('.subcategory-account').css('display', 'none');
                jQuery('#account-details').css('display', 'block');
                this.loaded = true
                // jQuery('html, body').animate({
                //     scrollTop: jQuery("#account-details").offset().top
                // }, 500);
            }
            else {
                this.showModal('Tous les champs doivent être remplis !')
                // alert('Tous les champs doivent être remplis !');
                this.loaded = true
            }
        },
        async getCart() {
            if(localStorage.cart_senseye_id) {
                this.cart = await APIRequest.detail('carts', localStorage.cart_senseye_id)
            }
            else {
                await this.useCart.getCart();
                this.cart = this.useCart.cartCopy;
            }
            
            
        },
        async userLogin (user) {
            this.loaded = false
            if (user != null) {
              
                this.errorLogin = '';
                await this.loadUserContent()
                //document.location.reload();
                this.loaded = true

            }
            else {
                this.displayLoginForm = true
                // console.log('user null')
                this.errorLogin = 'Vos identifiants sont incorrects.';
                this.loaded = true
            }

        },
        async loadUserContent() {
            
            let user = await this.useAuth.getUser();
            if(user) {
                this.account_name = user.name;
                this.account_surname = user.surname;
                this.account_username = user.username;
                this.account_email = user.email;
                this.account_phone = user.phone;
                this.account_id = user.id;
            }
            

           
            //this.loaded = false
            this.authLoaded = this.useAuth.authLoaded;
            if(this.useAuth.isAuth){
             
                this.user = this.useAuth.user;
                this.username = this.user.username;
                // this.authLoaded = true;
            }
            await this.getCart()
            this.displayLoginForm = !this.useAuth.isAuth;
            this.displayAccountContent = this.useAuth.isAuth;
            this.stripe = Stripe(this.stripeAPIToken);
            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');

            jQuery('.phone-input').on('input', function (event) {
                this.value = this.value.replace(/[^0-9]/g, '');
            });

            if (localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) {
                if (this.cart) {
                   
                    if (this.cart.user) {
                        
                        if (this.cart.user != null) {
                           
                            this.account_name = this.cart.user.name;
                            this.account_surname = this.cart.user.surname;
                            this.account_username = this.cart.user.username;
                            this.account_email = this.cart.user.email;
                            this.account_phone = this.cart.user.phone;
                            this.account_id = this.cart.user.id;
                            this.account_customer_stripe_id = this.cart.user.customer_stripe_id;

                            this.displayAccountContent = true

                         
                        }
                        else {
                            let Parameters = this.cart;
                            Parameters.user = {};
                            
                            Parameters.user.id = localStorage.id_user_senseye_auth;
                            this.cart = await APIRequest.update('carts', localStorage.cart_senseye_id, Parameters)
                            this.displayAccountContent = true
                            //document.location.reload()

                        }
                        if (this.$route.params.section) {
                            if (this.$route.params.section == 'commandes') {
                                this.displayOrders()
                            }
                        }
                       
                    }


                }
            


            }
            else {
              
                
                this.displayLoginForm = true
            }
           
         },
         async userRegister (user) {
            
            if (user != null) {
                this.displayRegisterForm=false
                this.user = user
                this.username = this.register_email
                this.password = this.register_password
                await this.loginUser();
            }
            else {

                this.loaded = true
                this.errorRegister = "Votre e-mail contient une erreur où un compte avec cette adresse mail existe déjà.";
            }

            

        },
    },
   
    updated() {
       
        if (this.displayFormCard == true) {

            this.card.mount('#card-element');

        }
    },
    created() {

        if(this.language=="fr")
    {

        useMeta({
    title: "VISAGES - Mon compte",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

document.querySelector('meta[name="description"]').setAttribute('content',"Connectez-vous où inscrivez-vous sur VISAGES.")

}
else
{

useMeta({
    title: "VISAGES - My account",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})
document.querySelector('meta[name="description"]').setAttribute('content',"Log in or register to VISAGES.")
}

},

    async mounted() {
        await this.loadUserContent()
        emitter.on('hide-popup-scan', function () {
            this.displayScanPopup = false

        }, this);
      
        
        

        emitter.on('cart-found', async function (user) {
            this.loaded = false
            await this.getCart()
            await this.reloadContents()
            if (this.cart) {
                if (this.cart.user) {
                    if (this.cart.user != null) {
                        this.account_name = this.cart.user.name;
                        this.account_surname = this.cart.user.surname;
                        this.account_username = this.cart.user.username;
                        this.account_email = this.cart.user.email;
                        this.account_phone = this.cart.user.phone;
                        this.account_id = this.cart.user.id;
                        this.account_customer_stripe_id = this.cart.user.customer_stripe_id;

                    }
                }
                this.loaded = true
            }

        }, this);

        // emitter.on('cart-created', async function () {

        //     document.location.reload();

        // }, this);

     



        
        this.loaded = true;
    }
}
</script>
<style>
/* .overlay {
    margin: auto;
    width: 50vw;
    height: 50vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
} */


.account-content .login-form button
{
	text-decoration:none !important;
}


.account-content .register-form input,
.account-content .login-form input
{
	margin-bottom:15px !important;
}

.account-content .popup-login
{
	max-width:450px;
}
.account-content .register-form h3,
.account-content .login-form h3
{
	text-align:center;
	font-weight:400;
}
.account-content .register-form button,
.account-content .login-form button
{
	width:100%;
}
.popup-share-scan {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.popup-share-scan .content .cross {
    position: absolute;
    right: -10px;
    top: -10px;
}

.popup-share-scan .content {
    background: #fff;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

a,
a.register_link {
    color: #0000FF;
    cursor: pointer;
}
</style>